<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Tanggal</label>
                                <Calendar :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label>Username</label>
                                <Dropdown v-model="filters.userid" :loading="loadingDropdownUserFilter" :options="dataDropdownUserFilter" optionLabel="username" optionValue="userid" placeholder="Pilih Username"  :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="kode_produk" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.user_hp !== null">
                                {{slotProps.data.user_hp.username}} 
                            </div>
                        </template>
                    </Column>
                    <Column field="absence_status" header="Status Absence">
                        <template #body="slotProps">
                            {{slotProps.data.absence_status}}
                        </template>
                    </Column>
                    <Column field="absence_photo" header="Photo">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.absence_photo">
                                <Image :src="slotProps.data.url_absence_photo" :alt="slotProps.data.url_absence_photo" width="100" preview />
                            </div>
                            <div v-else>
                                <Image src="no_image.jpg" alt="No Image" width="100" preview />
                            </div>
                        </template>
                    </Column>
                    <Column field="absence_note" header="Note">
                        <template #body="slotProps">
                            {{slotProps.data.absence_note}}
                        </template>
                    </Column>
                    <Column field="absence_date1" header="Waktu Absen">
                        <template #body="slotProps">
                            {{slotProps.data.absence_date1}} 
                            <div v-if="slotProps.data.absence_status == 'CUTI'">
                                - {{slotProps.data.absence_date2}}
                            </div>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteItem(slotProps.data)" v-if="permission.includes('delete')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label>Username</label>
                        <InputText v-model="item.user_hp.username" disabled/>
					</div>
                    <div class="field" v-if="item.absence_status == 'SAKIT'">
						<label for="tanggal">Tanggal</label>
                         <Calendar :manualInput="false" v-model="dateSakit" dateFormat="yy-mm-dd" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorEdit.absence_date1 }"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.absence_date1" >{{ errorEdit.absence_date1[0] }}</small >
					</div>
                    <div class="field" v-if="item.absence_status == 'CUTI'">
						<label for="tanggal">Tanggal</label>
                         <Calendar selectionMode="range" :manualInput="false" v-model="dateCuti" dateFormat="yy-mm-dd" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorEdit.absence_date1 }"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.absence_date1" >{{ errorEdit.absence_date1[0] }}</small >
					</div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Konfirmasi" :modal="true" class="p-fluid">
                    <div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="item">Apakah anda yakin akan mendelete presensi dari user <b>{{item.user_hp.username}}</b> ini ?</span>
					</div>
					<template #footer>
						<Button label="Tidak" icon="pi pi-times" class="p-button-text" @click="hideDeleteDialog"/>
						<Button label="Ya" icon="pi pi-check" class="p-button-text" @click="deletingItem" :loading="loadingDelete"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingEdit: false,
            loadingDelete: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingDropdownUserFilter: false,

            // dataDropdown
            dataDropdownUserFilter: null,

            // edit
            item: {},
            dateSakit: null,
            dateCuti: null,
            editItemDialog: false,
            deleteItemDialog: false,
            
            // filter
            period: new Date(),
            filters: {
                userid: null
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownUser('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
        date_label(){ 
            return moment(this.period, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        date_sakit(){ 
            return moment(this.dateSakit, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        date_cuti_start_label(){ 
            return moment(this.dateCuti[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        date_cuti_end_label(){ 
            return moment(this.dateCuti[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.duser.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownUserFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-hp',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownUserFilter = res.data.data;
                        this.loadingDropdownUserFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownUserFilter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		editItem(item) {
			this.item = item;

            if(this.item.absence_status == "SAKIT"){
                this.dateSakit = new Date(this.item.absence_date1); 
            }else if(this.item.absence_status == "CUTI"){
                this.dateCuti = [new Date(this.item.absence_date1),new Date(this.item.absence_date2)]; 
            }

			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            var date1 = null;
            var date2 = null;

            if(this.item.absence_status == 'SAKIT'){
                date1 = this.date_sakit;
                date2 = this.date_sakit;
            }else if(this.item.absence_status == 'CUTI'){

                if(
                    this.date_cuti_start_label == null || 
                    this.date_cuti_end_label == null || 
                    this.date_cuti_start_label == 'Invalid date' || 
                    this.date_cuti_end_label == 'Invalid date'
                ){  
                    alert('Pastikan rentang waktu sudah benar atau tidak kosong');
                    this.loadingEdit = false;
                    return
                }

                date1 = this.date_cuti_start_label;
                date2 = this.date_cuti_end_label;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/presensi/update',
                data: {
                    "absence_id" : this.item.absence_id,
                    "absence_date1" : date1,
                    "absence_date2" : date2,
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DELETE
		deleteItem(item) {
			this.item = item;
			this.deleteItemDialog = true;
		},
		hideDeleteDialog() {
			this.deleteItemDialog = false;
		},
		deletingItem() {
            this.loadingDelete = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/presensi/delete',
                data: {
                    "absence_id" : this.item.absence_id,
                },
            })
            .then((res) => {
                this.loadingDelete = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Dihapus', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.deleteItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingDelete = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Dihapus', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/presensi',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "userid" : this.filters.userid,
                    "tanggal" : this.date_label,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/presensi/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "userid" : this.filters.userid,
                    "tanggal" : this.date_label,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Presensi.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>